@import './winner-alternatives';

.#{$search-prefix}-winner-alternatives {
  $right-space: 8px;
  &__container--right-space {
    margin-right: $right-space;
  }

  &__container--top-space {
    margin-top: 4px;
    z-index: $z-index-s;
  }

  &--separator-top {
    $increment: 6%;
    $left-width: 47.5% + $increment;
    width: calc($left-width - $right-space);

    @media (min-width: 481px) and (max-width: 820px) {
      width: 100%;
    }

    &--grid {
      width: 100%;
    }
  }
}
