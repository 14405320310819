@import '~@andes/dropdown/base';
@import '~@andes/dropdown/index';
@import '~@andes/list/index';
@import '~@andes/dropdown/lib/styles/standalone/size/small';
@import '../../../styles/variables';

$dropdown-item-height: 41px;
$dropdown-popover-max-height: $dropdown-item-height * 5 + $dropdown-item-height / 2;

.andes-dropdown--small {
  button {
    order: unset;
  }

  & .andes-dropdown__trigger {
    border: none;
    color: $search-gray-666;
    padding-left: 0;
  }

  & .andes-dropdown__trigger:hover {
    color: $search-dodger-blue;
  }

  & .andes-dropdown__arrow {
    font-size: 18px;
    transform: rotate(0deg);
    transition: transform 0.3s ease-in;
  }

  & .andes-dropdown__arrow::after {
    border-right: 0.11111em solid $search-dodger-blue;
    border-bottom: 0.11111em solid $search-dodger-blue;
  }

  &.andes-dropdown--open .andes-dropdown__arrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease-out;
  }

  & .andes-list__item--selected {
    pointer-events: none;
  }

  & .andes-dropdown__popover {
    padding: 0;
  }
}

.andes-list {
  font-size: $font-size-14;
  font-weight: $font-weight-light;
  color: $search-gray-666;
  box-shadow: 0 1px 2px 0 $search-gray-120;

  & li.andes-list__item,
  a {
    display: block;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      background: $search-blue-dropdown-nonselected;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 0;
    }

    &:hover::after {
      width: 5px;
    }
  }

  &__item--selected::before {
    position: absolute;
    border-left: 5px solid $search-dodger-blue;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 0;
  }

  &__item-image-container {
    padding: 1.5px 5px;
  }
}

.#{$search-prefix}-price-filter-container .andes-dropdown--small {
  & .andes-list {
    & .andes-list__item {
      border-top: 1px solid $seach-background-spotlight-off;
    }
  }

  & .andes-dropdown__popover {
    max-height: 147px;
    min-width: 80px;
    overflow-y: auto;
    padding: 0;
  }

  .andes-dropdown__trigger {
    box-shadow: none;
  }
}

.#{$search-prefix}-sort-filter {
  & .andes-dropdown.andes-dropdown--standalone.andes-dropdown--small {
    .andes-floating-menu .andes-list__item {
      border-bottom: 1px solid $seach-background-spotlight-off;
      padding: 13px;
      height: 41px;
      display: flex;
      align-items: center;
    }

    & .andes-list__item--size-medium {
      & .andes-list__item-second-column,
      & .andes-list__item-first-column {
        padding: 0;
        margin-right: 0;
        height: 14px;
      }
    }
  }
}

.#{$search-prefix}-sort-filter .andes-dropdown.andes-dropdown--standalone.andes-dropdown--small {
  & .andes-list {
    & .andes-list__item,
    & .andes-list__item + .andes-list__item {
      border-bottom: 1px solid $seach-background-spotlight-off;
      padding: 0 0 0 16px;
    }
  }

  & .andes-dropdown__popover {
    margin-left: -10px;
    top: 32px;
    max-height: $dropdown-popover-max-height;
    overflow-y: auto;
    padding: 0;
    max-width: 134px;
    min-width: 134px;
  }

  .andes-dropdown__trigger {
    padding: 0;
    .andes-dropdown__standalone-arrow {
      margin: 3px 0 0 4px;
    }
  }

  .andes-list__item-image-container {
    max-width: 106px;
  }
}

.ui-search-faceted-search--searchbox-dropdown {
  .andes-dropdown__popover {
    width: calc(100% + 18px);
    left: -17px;
    top: 0 !important;
  }
}
