// sass-lint:disable no-misspelled-properties
@import '../../../../../styles/variables';

.ui-search-meliplay-intervention--grid {
  display: flex;
  flex-direction: column;
  width: 284px;
  margin-left: $search-spacing-16;
  margin-bottom: $search-spacing-16;
  overflow: hidden;
  box-shadow: 0 1px 1px 0 $andes-gray-100, 0 -1px 2px 0 $andes-gray-100;
  border: none;

  &:first-child {
    margin-left: 0;
  }

  .ui-search-item__highlight-label__text {
    padding: $search-spacing-3 $search-spacing-4;
    font-weight: $font-weight-semibold;
    border-radius: 3px;
    font-size: $font-size-14;
    line-height: $line-height-18;
  }

  &__image {
    display: flex;
    justify-content: center;
    height: 284px;
    width: 284px;
    border-bottom: 1px solid $search-gray-1400;
    object-fit: contain;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__data {
    padding: $search-spacing-16 $search-spacing-20;
    gap: $search-spacing-13;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__title {
    line-height: $line-height-18;
    font-size: $font-size-14;
    font-weight: $font-weight-normal;
    color: $search-gray-1000;
  }

  &__description {
    color: $andes-text-color-secondary;
    font-size: $font-size-12;
    line-height: $line-height-15;
    font-weight: $font-weight-normal;
  }

  &__label {
    display: flex;
    gap: $search-spacing-4;
    align-items: center;
    margin-top: $search-spacing-13;

    &-text {
      color: $search-green-100;
      font-weight: $font-weight-normal;
      font-size: $font-size-14;
      line-height: $line-height-18;
    }
  }

  &__button {
    border: 1px solid !important;

    .ui-search-icon--play {
      margin-right: $search-spacing-7;
    }
  }

  @media (width <= 1164px) and (width >= 1024px), (width <= 861px) and (width >= 720px) {
    width: 240px;
    margin-left: $search-spacing-12;
    margin-bottom: $search-spacing-12;

    &__image {
      height: 240px;
      width: 240px;
    }
  }
}
