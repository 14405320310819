@import '../../../styles/variables';
@import '~@andes/tooltip/index';
@import '~@andes/radio-button/index';
@import '~@andes/progress-indicator-circular/index';

.tooltip-periodicity h3 {
  font-weight: $font-weight-bold;
  font-size: $font-size-16;
}

.tooltip-periodicity label {
  font-size: $font-size-16;
}

.#{$search-prefix}-alert__card {
  display: flex;
  position: relative;
  align-items: center;
}

.#{$search-prefix}-alert__card--edit-alert {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 14px;
  width: max-content;
}

.#{$search-prefix}-alert__btn-alert {
  background-color: transparent;
  border: none;
  display: flex;
  margin: 0;
  padding: 0;
  outline: none;
  align-items: center;
  font-size: $font-size-14;

  p {
    color: $search-dodger-blue;
    cursor: pointer;
    margin-left: 2px;
  }
}

.#{$search-prefix}-alert__card--alert {
  width: max-content;
  margin-bottom: 14px;
  margin-top: -7px;
}

.#{$search-prefix}-alert__not_logged {
  margin-bottom: 14px;
  margin-top: -7px;
}

.#{$search-prefix}-alert__card--alert::before,
.#{$search-prefix}-alert__card--edit-alert::before {
  background-color: transparent;
  content: none;
  height: 0;
  margin: 0;
  width: 0;
}

.#{$search-prefix}-alert__card::before {
  background-color: $search-gray-700;
  content: '';
  height: 16px;
  margin: 0 10px 0 12px;
  width: 1px;
}

.#{$search-prefix}-alert__btn {
  background-color: transparent;
  border: none;
  display: flex;
  margin-right: 10px;
  outline: none;
}

.#{$search-prefix}-alert__alert-message {
  width: 260px;
  z-index: $z-index-xl;
  font-size: $font-size-12;
  padding: 10px 0 10px 16px;

  .andes-message__badge--neutral {
    display: none;
  }
}

.#{$search-prefix}-alert__icon-alert {
  width: 23px;
  cursor: pointer;
}

.#{$search-prefix}-alert__icon-alert-edit {
  width: 23px;
}

.#{$search-prefix}-alert__content {
  margin-left: 7px;
}

.#{$search-prefix}-alert-edition-tooltip {
  width: 250px;
  padding: 9px;
  border-radius: 2px;

  p {
    margin-bottom: 5px;
  }
}

.#{$search-prefix}-alert-edition-tooltip__content {
  display: flex;
  font-size: $font-size-12;
  line-height: $font-size-12;
}

.#{$search-prefix}-alert__alert-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: -7px;
  font-size: $font-size-14;
}

.#{$search-prefix}-alert__alert-edit-text {
  margin-left: 3px;
  font-size: $font-size-14;
  color: $search-dodger-blue;
  margin-top: 2px;
}

.#{$search-prefix}-alert-edition-tooltip__content-alert {
  display: flex;
  flex-direction: column;
  font-size: $font-size-14;
  line-height: $font-size-18;
  padding: 2px 0;
}

.#{$search-prefix}-alert-edition-tooltip__text {
  color: $search-gray-1000;
  font-weight: $font-weight-light;
}

.#{$search-prefix}-alert-edition-tooltip__text-alert {
  color: $search-gray-1000;
  font-weight: $font-weight-normal;
  margin-bottom: 8px;
}

.#{$search-prefix}-alert-edition-tooltip__action-link-alert {
  margin: 0 3px;
  font-weight: $font-weight-semibold;
}

.#{$search-prefix}-alert-edition-tooltip__action-link {
  margin: 0 3px;
  font-weight: 100;
}

.andes-tooltip:not(.ui-search-tooltip-filter) {
  .andes-tooltip-content {
    .andes-tooltip-arrow::before {
      top: -5px;
      width: 0.5em;
      height: 0.5em;
    }
  }
}
