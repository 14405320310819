.ui-compats-wrapper {
  margin-bottom: 8px;
  .ui-compats-header__container-message{
    margin: 0px;
  }

  &__sticky {
    position: sticky;
    top: -0.1px;
    z-index: +12;
  }

  &__shadow-on {
    box-shadow: 0 6px 16px rgba($andes-black, 0.16);
  }

  &__animation-message{
    animation: messageAnimation 0.7s linear 0s 1 normal forwards;
    .ui-compats-header__container-message{
      margin: 0;
    }
    .ui-compats-header__container-message .andes-message{
      border-radius: 0;
    }
  }

  // sass-lint:disable class-name-format //
  .ui-compats-header--COMPATS_CARD_UNKNOWN {
    margin-top: 8px;
  }
}

.ui-compats__inputs {
  .andes-list__item--size-medium .andes-list__item-first-column {
    padding: 16px 0;
  }
}

[data-site='MS'] {
  .ui-compats-wrapper__sticky {
    top: 90px;
    box-shadow: 0 6px 16px rgba($andes-black, 0.16);
  }
}

.ui-compats-header__container-message{
  .andes-message--quiet{
    background: $andes-white;
  }
}

@media (min-width: $breakpoint-desktop) {
  .ui-compats-header__container-message{
    .andes-message--quiet {
      .andes-message__buttons-container {
        margin-top: 11px;
        button.andes-button.andes-button--medium.andes-button--loud {
          background: $andes-blue-150;
          color: $andes-blue-500;
        }
        .andes-button--transparent:hover {
          background: transparent;
        }
      }
    }
  }
}


@media (max-width: $breakpoint-desktop) {
  [data-site='MS'] {
    .ui-compats-wrapper__sticky {
      box-shadow: none;
      top: 80px;
    }
  }
  .ui-compats-wrapper {
    .ui-compats-header__container-message{
      margin: 12px;
    }
    &__animation-message{
      .ui-compats-header__container-message{
        margin: 0px;
      }
    }
  }
}
@keyframes messageAnimation {
  0% {
    opacity: 0;
    transform: translateY(-250px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}