@import '../../../../styles/variables';

.#{$search-prefix}-item__highlight-label {
  border-radius: $border-radius-3;
  display: table-cell;
  font-weight: $font-weight-semibold;
  padding: 2px 4px;
  width: fit-content;

  &--no-background {
    padding: 0;
    font-weight: $font-weight-normal;
  }

  // sass-lint:disable no-misspelled-properties
  &__container {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .#{$search-prefix}-icon.#{$search-prefix}-icon--cart-volume-discount {
    min-width: 16px;
    position: relative;
    top: 1px;
  }

  &--secondary-label {
    display: inline-block;
    font-size: $font-size-12;
    color: $search-gray-r080;
  }

  &--meli_choice {
    display: flex;
    vertical-align: middle;
    align-items: center;
    width: fit-content;
    padding: 1px 5px 0 3px;
    height: 20px;
  }

  &--media {
    margin-bottom: 0;
    padding-left: 0;

    .#{$search-prefix}-item__highlight-label__container {
      align-items: flex-end;

      > svg {
        height: 11px;
      }

      .#{$search-prefix}-item__highlight-label__text {
        font-size: $font-size-10;
        color: $search-dodger-blue;
        margin-left: 3px;
      }
    }
  }

  &--pricing_rebates {
    .#{$search-prefix}-item__highlight-label {
      &__text {
        line-height: $line-height-20;
        border-radius: $search-spacing-2;
      }
    }
  }
}

// sass-lint:disable no-qualifying-elements
body[data-site='PI'] {
  .#{$search-prefix}-item__highlight-label {
    &--media {
      padding-bottom: $search-spacing-10;
    }
  }
}

.#{$search-prefix}-result .#{$search-prefix}-item__highlight-group {
  display: flex;
  flex-direction: column;

  .#{$search-prefix}-item__highlight-label {
    border-radius: $search-spacing-2;
    padding: 0 $andes-spacing-4 $search-spacing-2;
    margin: 0 0 $andes-spacing-4;
    font-size: $font-size-12;
  }

  &-hint:last-child {
    font-size: $font-size-12;
    line-height: $line-height-s;
    font-weight: $font-weight-regular;
    margin-bottom: $andes-spacing-12;
  }

  & + .#{$search-prefix}-item__group--attributes {
    margin-top: 0;
  }
}
