.#{$search-prefix}-footer-components {
  background-color: $andes-bg-secondary;
  display: flex;
  justify-content: center;
  margin-bottom: (-$andes-spacing-64);
  width: 100%;

  &__wrapper {
    display: flex;
    max-width: 1215px;
    width: 100%;
    justify-content: end;

    &.reduced--search,
    &.reduced--new-categories {
      padding-right: 28px;
      padding-left: 10px;
    }
  }

  &__container {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin-bottom: $andes-spacing-64;
    margin-top: $andes-spacing-32;
    width: 1200px;
    padding: 0 $andes-spacing-12 0px 0px;

    &.reduced {
      &--new-categories,
      &--search {
        width: 885px;
        padding: 0;
        margin-right: 0;
      }

      &--new-categories {
        margin-right: 12px;
      }
    }
  }
}
