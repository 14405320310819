@import '../../../../../styles/variables';

$color-2450293: #1717ff;
$color-2450308: #fff;
$color-2450295: #000;
$color-2450312: #fcb1be;
$color-2450307: #f00;
$color-2450299: #666;
$color-2450294: #e1e1e1;
$color-2450291: #a0522d;
$color-2450326: #ff00ec;
$color-2450314: #0da600;
$color-2450325: #0f5299;
$color-2450303: #cbcfd0;
$color-2450322: #830500;
$color-2450311: #9f00ff;
$color-2450280: #fadbe2;
$color-2450278: #83ddff;
$color-2450296: #ffed00;
$color-2450281: #f5f3dc;
$color-2450306: #013267;
$color-2450327: #ff8c00;
$color-2450286: #ffe4c4;
$color-2450302: #6fa8dc;
$color-2450310: #3f7600;
$color-2450289: #ffd700;
$color-2450319: #9ff39f;
$color-2450292: #af8650;
$color-2450318: #fa8072;
$color-2450290: #4e0087;
$color-2450283: #e0ffff;
$color-2450324: #003d00;
$color-2450298: #dcecff;
$color-2450288: #1e6e7f;
$color-2450284: #ff51a8;
$color-2450320: #40e0d0;
$color-2450309: #bf9000;
$color-2450285: #d06ea8;
$color-2450321: #cc87ff;
$color-2450304: #eacb53;
$color-2450279: #7a64c6;
$color-2450313: #f9ac95;
$color-2450300: #ffffe0;
$color-2450287: #faebd7;
$color-2450317: #c63633;
$color-2450301: #f0e68c;
$color-2450316: #d9d2e9;
$color-2450315: #0ff;
$color-2450297: #5d3806;
$color-2450328: #d2691e;
$color-2450305: #73e129;
$color-2450282: #9b3f14;
$color-2450323: #fdaf20;

.#{$search-prefix}-filter-colors {
  box-shadow: inset 0 0 0 1px $search-gray-150;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 24px;
  position: relative;
  transition: transform $quick-transition-duration;
  width: 24px;

  &::before {
    box-shadow: 0 0 0 3px $andes-white, 0 5px 8px $search-gray-666, 0 0 0 4px $search-gray-300;
    border-radius: 50%;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: opacity $quick-transition-duration;
    top: 0;
    width: 100%;
  }

  &:hover {
    box-shadow: inset 0 0 0 1px transparent;

    &::before {
      opacity: 1;
    }
  }

  &-2450293 {
    background-color: $color-2450293;
  }

  &-2450295 {
    background-color: $color-2450295;
  }

  &-2450308 {
    background-color: $color-2450308;
  }

  &-2450312 {
    background-color: $color-2450312;
  }

  &-2450299 {
    background-color: $color-2450299;
  }

  &-2450307 {
    background-color: $color-2450307;
  }

  &-2450294 {
    background-color: $color-2450294;
  }

  &-2450291 {
    background-color: $color-2450291;
  }

  &-2450326 {
    background-color: $color-2450326;
  }

  &-2450314 {
    background-color: $color-2450314;
  }

  &-2450325 {
    background-color: $color-2450325;
  }

  &-2450303 {
    background-color: $color-2450303;
  }

  &-2450322 {
    background-color: $color-2450322;
  }

  &-2450311 {
    background-color: $color-2450311;
  }

  &-2450280 {
    background-color: $color-2450280;
  }

  &-2450278 {
    background-color: $color-2450278;
  }

  &-2450296 {
    background-color: $color-2450296;
  }

  &-2450281 {
    background-color: $color-2450281;
  }

  &-2450306 {
    background-color: $color-2450306;
  }

  &-2450327 {
    background-color: $color-2450327;
  }

  &-2450286 {
    background-color: $color-2450286;
  }

  &-2450302 {
    background-color: $color-2450302;
  }

  &-2450310 {
    background-color: $color-2450310;
  }

  &-2450289 {
    background-color: $color-2450289;
  }

  &-2450319 {
    background-color: $color-2450319;
  }

  &-2450292 {
    background-color: $color-2450292;
  }

  &-2450318 {
    background-color: $color-2450318;
  }

  &-2450290 {
    background-color: $color-2450290;
  }

  &-2450283 {
    background-color: $color-2450283;
  }

  &-2450324 {
    background-color: $color-2450324;
  }

  &-2450298 {
    background-color: $color-2450298;
  }

  &-2450288 {
    background-color: $color-2450288;
  }

  &-2450284 {
    background-color: $color-2450284;
  }

  &-2450320 {
    background-color: $color-2450320;
  }

  &-2450309 {
    background-color: $color-2450309;
  }

  &-2450285 {
    background-color: $color-2450285;
  }

  &-2450321 {
    background-color: $color-2450321;
  }

  &-2450304 {
    background-color: $color-2450304;
  }

  &-2450279 {
    background-color: $color-2450279;
  }

  &-2450313 {
    background-color: $color-2450313;
  }

  &-2450300 {
    background-color: $color-2450300;
  }

  &-2450287 {
    background-color: $color-2450287;
  }

  &-2450317 {
    background-color: $color-2450317;
  }

  &-2450301 {
    background-color: $color-2450301;
  }

  &-2450316 {
    background-color: $color-2450316;
  }

  &-2450315 {
    background-color: $color-2450315;
  }

  &-2450297 {
    background-color: $color-2450297;
  }

  &-2450328 {
    background-color: $color-2450328;
  }

  &-2450305 {
    background-color: $color-2450305;
  }

  &-2450282 {
    background-color: $color-2450282;
  }

  &-2450323 {
    background-color: $color-2450323;
  }
}
