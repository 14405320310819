@import '@ui-components-web/ui-pills-builder/pills.builder.desktop';
@import './shared';

.meli-plus,
.meli_plus,
.meliplus {
  svg {
    width: auto;
    height: $andes-spacing-16;
  }
}

.fulfillment,
.ui-fulfillment-pill {
  svg {
    width: auto;
    height: 14px;
  }

  .ui-pb-label {
    vertical-align: text-top;
    font-size: $font-size-14;
  }
}

.ui-tradein-pill {
  .ui-pb-label {
    font-size: $font-size-14;
  }
}
