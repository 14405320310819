@import '@andes/search-box/index';
@import '../../../styles/variables';
@import 'vis-faceted-search/src/desktop/index';

$header-height: 56px;
$border: 1px solid $andes-gray-100;
$control-button-size: 29px;
$group-height: 500px;
$card-image-size: 90px;
$item-border-radius: 8px;
$dropdown-item-height: 48px;
$dropdown-fade-height: floor($dropdown-item-height / 2);
$dropdown-full-height: floor($group-height / $dropdown-item-height) * $dropdown-item-height;
$dropdown-max-height: $dropdown-full-height - floor($dropdown-item-height / 3);
$white-transparent: hsl(0deg 0% 100% / 0%);
$infowindow-width: 220px;
$text-input-border-shadow: 0 0 0 1px rgb(0 0 0 / 25%);

.#{$search-prefix}-results--map {
  width: 100%;
  padding-top: $andes-spacing-12;

  .#{$search-prefix}-listing-disclaimer--map {
    margin-top: $andes-spacing-20;
  }
}

.#{$search-prefix}-view-options__container--map {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: $andes-spacing-24;

  .#{$search-prefix}-breadcrumb {
    overflow: hidden;

    .andes-breadcrumb {
      margin-bottom: 0;
    }

    &__title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.#{$search-prefix}-layout--map {
  width: 100%;
  display: flex;
  overflow: hidden;
  box-shadow: $shadow-flat;
  border-radius: $border-radius-6;

  &__left-content {
    background: $andes-white;
    width: $breakpoint-340;
    min-width: $breakpoint-340;
    height: $group-height + $header-height;
  }

  &__right-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &--map {
      flex-grow: 1;
      position: relative;
    }

    &--map::before {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba($andes-black, 0.01);
      box-shadow: inset 4px 0 8px $andes-gray-100;
      z-index: $z-index-base;
      pointer-events: none;
    }
  }
}

.#{$search-prefix}-map-list {
  &__header {
    height: $header-height;
    padding: 0 $andes-spacing-16;
    display: flex;
    align-items: center;
    color: $andes-gray-550;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    border-bottom: $border;

    .#{$search-prefix}-link {
      font-weight: $font-weight-semibold;
    }

    .#{$search-prefix}-link .#{$search-prefix}-icon--close {
      vertical-align: middle;
      margin-left: $andes-spacing-12;
      fill: $search-dodger-blue;
    }

    .#{$search-prefix}-link:hover .#{$search-prefix}-icon--close,
    .#{$search-prefix}-link:focus .#{$search-prefix}-icon--close {
      fill: $search-view-option-tooltip-link;
    }

    .#{$search-prefix}-link:active .#{$search-prefix}-icon--close {
      fill: $andes-blue-700;
    }
  }

  &__group {
    height: $group-height;
    background-color: $search-gray-200;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &__item {
    padding: $andes-spacing-12 $andes-spacing-16;
    position: relative;
    min-height: 114px;
    border-bottom: $border;
    background-color: $andes-white;

    &:last-child {
      border-bottom: none;
    }

    .#{$search-prefix}-result {
      &__wrapper {
        display: flex;
        padding-right: $andes-spacing-20;
      }

      &__main-image-link {
        display: block;
        width: $card-image-size;
        margin: 0 $andes-spacing-12 0 0;
      }

      &__main-image-internal {
        border-radius: $search-spacing-4;
        width: $card-image-size;
        height: $card-image-size;
        object-fit: cover;
      }

      &__available-units {
        font-size: $font-size-12;
        color: $search-gray-1000;
        font-weight: $font-weight-bold;
      }

      &__loader {
        background-color: $search-gray-93;

        &-content-1 {
          width: 140px;
        }

        &-content-2 {
          width: 80px;
        }

        &-content-3 {
          width: 160px;
        }

        &-content-height {
          height: 11px;
        }
      }

      &__content-link {
        overflow: hidden;
      }

      &__content {
        &-separator--2 {
          margin-bottom: $search-spacing-2;
        }

        &-separator--4 {
          margin-bottom: $search-spacing-4;
        }

        &-separator--8 {
          margin-bottom: $search-spacing-8;
        }

        // sass-lint:disable no-vendor-prefixes
        &-title {
          line-height: $line-height-15;
          font-size: $font-size-12;
          color: $andes-gray-900;
          -webkit-line-clamp: 1;
        }

        &-location,
        &-attributes {
          color: $search-gray-450;
          line-height: $line-height-125;
          font-weight: $font-weight-regular;
          font-size: $font-size-12;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: $andes-spacing-4;
        }

        &-location:last-child {
          margin-bottom: 0;
        }
        &-attributes:last-child {
          margin-bottom: 0;
        }

        .#{$search-prefix}-price {
          color: $search-gray-r080;
        }

        .#{$search-prefix}-price::before,
        .#{$search-prefix}-price::after {
          margin: 0;
        }
        .#{$search-prefix}-price__front-price {
          font-size: $font-size-12;
          white-space: nowrap;
          font-weight: $font-weight-regular;
        }
        .#{$search-prefix}-price__part {
          font-weight: $font-weight-normal;
          line-height: $line-height-125;
          font-size: $font-size-18;
        }
      }

      &:hover::before,
      &:hover::after {
        content: '';
        position: absolute;
        width: 2px;
        top: $andes-spacing-4;
        bottom: $andes-spacing-4;
        background: $andes-blue-500;
      }

      &:hover::before {
        left: $search-spacing-2;
        border-radius: $item-border-radius 0 0 $item-border-radius;
      }

      &:hover::after {
        left: $search-spacing-4;
        border-radius: 0 $item-border-radius $item-border-radius 0;
      }

      &:hover {
        .#{$search-prefix}-bookmark {
          pointer-events: initial;
          opacity: 1;
        }
      }

      &__bookmark {
        pointer-events: none;
        position: absolute;
        right: $andes-spacing-12;
        top: 0;
        transition: opacity $quick-transition-duration;

        .#{$search-prefix}-bookmark {
          opacity: 0;
        }

        .#{$search-prefix}-bookmark--hover-disabled {
          opacity: 1;
        }

        .#{$search-prefix}-bookmark--active {
          opacity: 1;
        }

        .#{$search-prefix}-bookmark__icon-bookmark,
        .#{$search-prefix}-bookmark__icon-bookmark-fill {
          height: 16px;
          top: 12px;
        }
      }
    }

    &--development {
      .#{$search-prefix}-result:hover::before,
      .#{$search-prefix}-result:hover::after {
        background: $andes-blue-700;
      }
    }

    .#{$search-prefix}-result {
      box-shadow: none;
    }
  }

  .#{$search-prefix}-pagination:last-child {
    margin: 0;
  }

  .#{$search-prefix}-map-pagination {
    background: $andes-bg-secondary;
    border-top: $border;
    padding: $andes-spacing-16;
    margin-bottom: 0;
  }
}

.#{$search-prefix}-map-zrp {
  background: $andes-bg-secondary;
  height: 100%;

  .ui-empty-state {
    &__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: $andes-spacing-48;
      text-align: center;
    }

    &__title {
      font-size: $font-size-18;
      font-weight: $font-weight-semibold;
      margin-top: $andes-spacing-16;
      margin-bottom: $andes-spacing-8;
    }

    &__description {
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
    }
  }

  &__icon {
    height: auto;
    width: 130px;
  }
}

.#{$search-prefix}-map-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $andes-bg-secondary;
  height: $header-height;
  padding: 0 $search-spacing-10;

  &__primary-container {
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    padding-right: $andes-spacing-28;

    &--left {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &--left > * ~ * {
      margin-left: $andes-spacing-16;
    }

    .andes-dropdown {
      line-height: $line-height-s;

      &__trigger {
        box-shadow: 0 0 0 1px $andes-gray-100 !important;

        &:focus {
          box-shadow: inset 0 0 0 2px $andes-blue-500 !important;
        }
      }

      .andes-dropdown__standalone-arrow {
        margin: 1px 0 0 4px !important;
      }
    }
  }

  &__switch {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: $andes-spacing-16;
  }

  &__switch-label {
    margin-left: $andes-spacing-4;
    font-size: $font-size-12;
  }

  &__location-dropdown {
    max-width: 300px;

    .andes-form-control--search-box,
    .andes-dropdown__no-results {
      box-sizing: unset;
    }

    .andes-dropdown__popover {
      top: -1.1em;
    }

    .andes-list__item-primary {
      max-width: 432px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-item--active {
      background-color: $andes-gray-040;
      transition: background-color 0.1s ease-out;
    }
  }

  &__location-search {
    width: 100%;
    position: relative;
    color: $andes-gray-550;

    .andes-form-control__message {
      min-height: 0;
      margin-top: 0;
    }
  }

  &__secondary-container {
    margin-left: auto;
    flex-shrink: 0;

    div[role="dialog"] {
      z-index: 1 !important;
    }

    .#{$search-prefix}-price-filter-container {
      .andes-form-control__control {
        font-size: 13px;
        min-height: 25px;
        width: 84px;
      }

      .andes-form-control__field {
        font-size: $font-size-13;
        height: 25px;
      }

      .andes-form-control__field::placeholder {
        font-size: $font-size-14;
        color: $search-gray-r050;
      }
    }
  }

  .andes-dropdown.andes-dropdown--standalone {
    display: flex;
    align-items: center;

    .andes-floating-menu .andes-dropdown__trigger {
      color: $andes-gray-900;
      box-shadow: 0 0 0 1px $andes-gray-100;
      border-radius: $border-radius-6;
      padding: $search-spacing-8 $search-spacing-10;
      height: 36px;
      min-width: 130px;
      font-size: $font-size-14;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:focus {
        background: $andes-white;
        box-shadow: inset 0 0 0 2px $andes-blue-500;
      }

      &:focus .andes-dropdown__arrow {
        transform: rotate(180deg);
      }

      .andes-dropdown__arrow {
        transition: transform 0.3s ease-in;
      }

      .andes-dropdown__arrow::after {
        border-color: $andes-blue-500;
        font-size: $font-size-18;
      }
    }

    .andes-floating-menu__no-results {
      box-sizing: initial;
    }

    .andes-dropdown__display-values {
      line-height: $line-height-s;
    }

    .andes-card__content::after {
      content: '';
      inset: #{$dropdown-max-height - (2 * $dropdown-fade-height)} 0 0 0;
      position: absolute;
      pointer-events: none;
      background: linear-gradient(0deg, $andes-white 0%, $white-transparent $dropdown-fade-height);
      border-radius: $border-radius-6;
    }

    .andes-list {
      padding-bottom: #{$dropdown-fade-height / 2};

      &__item {
        font-weight: $font-weight-regular;
      }

      &__item--selected {
        font-weight: $font-weight-bold;
      }

      &__item-primary {
        white-space: nowrap;
      }
    }
  }

  .andes-list--size-default {
    max-height: $dropdown-max-height;
    overflow-y: scroll;

    .andes-list__item {
      height: $dropdown-item-height;
      padding: 0 $andes-spacing-12;
      font-size: $font-size-16;
      font-weight: $font-weight-regular;
      display: flex;
      align-items: center;
      border: none;

      &--selected .andes-list__item-primary {
        color: $andes-blue-500;
        font-weight: $font-weight-semibold;
      }

      &--selected::before {
        left: 2px;
        top: 2px;
        bottom: 2px;
        border-radius: 2px;
        border-left: 2px solid $andes-blue-500;
      }

      &:hover::after {
        content: none;
      }
    }
  }

  &__filters-button {
    &.andes-button {
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      height: 36px;
      min-width: 98px;
      padding: 0 10px;
    }

    &--icon {
      width: 16px;
      height: 16px;
    }

    &--text-highlight {
      color: $andes-blue-500;
    }

    .andes-button__content {
      justify-content: center;
    }

    .andes-button__content path {
      fill: $andes-accent-color;
    }

    &:hover {
      background-color: $andes-blue-100;

      .andes-button__content path {
        stroke: $andes-accent-color;
      }
    }

    .andes-button__text {
      margin-left: $andes-spacing-8;
      white-space: nowrap;
      overflow: unset;
      flex: auto;

      &:last-child {
        margin-left: $andes-spacing-4;
      }
    }
  }

  &__filters-tooltip {
    width: 360px;
    padding: 0;
    z-index: $z-index;

    .andes-tooltip-data {
      overflow-y: scroll;
      max-height: $group-height - $andes-spacing-8;
      padding: $andes-spacing-24;
    }

    .andes-tooltip-button-close {
      display: none;
    }

    .ui-search-applied-filters__link .andes-tag {
      background-color: $andes-bg-secondary;
      border-radius: $border-radius-4;
    }

    .ui-search-filter-groups {
      margin-top: 0;

      .ui-search-filter-dl {
        padding-right: 0;
      }

      .ui-search-filter-name {
        color: $andes-gray-550;
      }

      .ui-search-filter-results-qty {
        color: $andes-gray-250;
      }

      .andes-form-control__field {
        border: 1px solid $andes-gray-250;
      }
    }

    .ui-search-filter-highlighted {
      width: 100%;

      &__container {
        border: none;
        padding: $andes-spacing-8 0;
      }
    }
  }

  .#{$faceted-desktop-prefix} {
    padding: 0;
    background-color: unset;
    box-shadow: none;

    &__main-container {
      flex-wrap: nowrap;
    }

    &__elem {
      margin-right: 0;
      margin-left: $search-spacing-8;

      &:first-child {
        margin-left: 0;

        .#{$faceted-desktop-prefix}-dropdown--small {
          width: 130px;
        }
      }
    }

    &-searchbox__floating-menu {
      overflow: inherit;
    }

    .andes-list {
      min-width: 100%;
      overflow-y: auto;
    }

    .andes-checkbox {
      padding-left: 0;

      &__input {
        left: 2px;
      }

      &__label.andes-checkbox__label-text {
        font-size: $font-size-12;
        white-space: nowrap;
      }
    }

    &-dropdown.andes-dropdown {
      &.andes-dropdown--form .andes-dropdown__trigger {
        box-shadow: 0 0 0 1px $andes-gray-100;
        height: $search-spacing-36;
        margin: 0;
        min-height: $search-spacing-36;

        &:focus:not(:focus-visible) {
          box-shadow: 0 0 0 1px $andes-gray-100;
        }
      }

      &--open.andes-dropdown--form .andes-dropdown__trigger {
        background-color: $andes-white;
        box-shadow: 0 0 0 2px $andes-blue-500;
      }

      .andes-popper {
        width: 100% !important;
      }
    }

    &-searchbox {
      &__icon {
        bottom: 0.2rem;
      }

      .andes-form-control__field::placeholder {
        color: $andes-gray-550;
      }

      .andes-list {
        min-width: 250px;
        overflow-y: auto;
        max-height: 220px;
      }
    }

    .andes-form-control--textfield {
      .andes-form-control__control {
        background-color: inherit;
        min-height: $search-spacing-36;
        box-shadow: 0 0 0 1px $andes-gray-100;

        .andes-form-control__field {
          background-color: inherit;
          font-size: $font-size-14;
        }

        .andes-form-control__field::placeholder {
          font-size: $font-size-14;
        }
      }
    }

    .andes-form-control--focused .andes-form-control__control {
      background-color: $andes-white;
      box-shadow: 0 0 0 2px $andes-blue-500;
    }

    &-dropdown--small {
      width: 140px;
    }
  }
}

.ui-map-control {
  &-button-container {
    overflow: hidden;
    background: $andes-white;
    box-shadow: 0 1px 3px $andes-gray-250;
    border-radius: $border-radius-6;
    transition: box-shadow $quick-transition-duration ease-out;
    pointer-events: auto;
  }

  &-button {
    border: none;
    outline: none;
    cursor: pointer;
    background: $andes-white;
    font-family: $font-family-primary;

    &:hover {
      background: $andes-blue-150;
    }

    &:active {
      background: $andes-blue-200;
    }
  }

  &-custom-controls {
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 24px;
    right: 24px;
    pointer-events: none;

    & > * ~ * {
      margin-left: $andes-spacing-8;
    }
  }

  &-button-separator {
    border-color: $andes-gray-070;
    border-style: solid;
    border-width: 1px 0 0;
    margin: 0;
  }

  &-map-type-button {
    font-weight: $font-weight-semibold;
    font-size: $font-size-14;
    color: $andes-blue-500;
    text-transform: capitalize;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    height: $control-button-size;
    padding: $search-spacing-6 $search-spacing-12;
  }

  &-zoom-container {
    width: $control-button-size;
    pointer-events: auto;
  }

  &-zoom-button {
    display: flex;
    align-items: baseline;
    justify-content: center;
    padding: $search-spacing-6;
    width: 100%;

    // Older chrome versions need svgs to have an explicit width and height
    & > svg {
      min-width: 16px;
      min-height: 16px;
    }
  }
}

.#{$search-prefix}-map-loader-error {
  width: 100%;
  height: $group-height;

  .ui-empty-state {
    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__title {
      font-size: $font-size-18;
      font-weight: $font-weight-semibold;
      margin-top: 1.6em;
      margin-bottom: $andes-spacing-16;
    }

    &__description {
      color: $search-gray-r060;
      font-weight: $font-weight-regular;
    }
  }

  &__icon {
    width: 130px;
    height: auto;
  }
}

.#{$search-prefix}-result__infowindow {
  width: $infowindow-width;
  border-radius: $border-radius-6;
  box-shadow: $shadow-elevated;

  .#{$search-prefix}-result {
    &__infowindow-image-link {
      display: block;
      width: $infowindow-width;
      height: 150px;
    }

    &__main-image-internal {
      width: $infowindow-width;
      height: 150px;
      object-fit: cover;
      border-radius: $border-radius-6 $border-radius-6 0 0;
    }

    &__available-units {
      font-weight: $font-weight-bold;
    }

    &__content {
      padding: $search-spacing-8;
      font-size: $font-size-12;
      color: $andes-gray-900;

      &-separator--2 {
        margin-bottom: $search-spacing-2;
      }

      &-separator--4 {
        margin-bottom: $search-spacing-4;
      }

      // sass-lint:disable no-vendor-prefixes
      &-title {
        line-height: $line-height-15;
        font-size: $font-size-12;
        color: $andes-gray-900;
        -webkit-line-clamp: 1;
      }

      &-location,
      &-attributes {
        color: $search-gray-450;
        font-size: $font-size-12;
        margin-bottom: $andes-spacing-4;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .#{$search-prefix}-price__second-line {
    font-size: $font-size-18;
    line-height: 15px;
  }

  .#{$search-prefix}-item__group--rental-type {
    margin-bottom: 0;

    .#{$search-prefix}-item__highlight-group-hint:last-child {
      margin-bottom: $andes-spacing-4;
    }
  }
}

// BOOKMARK

.#{$search-prefix}-bookmark {
  height: 36px;
  position: relative;
  width: 36px;
  z-index: $z-index-base;
}

.#{$search-prefix}-bookmark__btn {
  align-items: center;
  background: rgba($andes-white, 0.7);
  border: 0;
  border-radius: 50%;
  color: $andes-blue-500;
  content: '';
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  padding: 0;

  &:hover {
    color: $andes-blue-700;
  }
}

.#{$search-prefix}-bookmark__icon-bookmark,
.#{$search-prefix}-bookmark__icon-bookmark-fill {
  position: absolute;
  height: 18px;
  width: 18px;
  z-index: $z-index-s;
}

.#{$search-prefix}-bookmark__icon-bookmark {
  fill: transparent;
  stroke: currentcolor;
  stroke-width: 2px;
}

.#{$search-prefix}-bookmark__icon-bookmark-fill {
  fill: currentcolor;
  stroke: transparent;
  opacity: 0;

  &--active {
    opacity: 1;
  }
}

.#{$search-prefix}-map-cluster__label {
  font-family: $font-family-primary !important;
  font-size: $font-size-10 !important;
  color: $andes-white !important;

  &--selected {
    color: $andes-blue-600 !important;
  }
}
