.#{$search-prefix}-threshold-label {
  display: block;
  margin-bottom: 8px;
  animation: fadeIn 0.3s ease-in-out;
}

.#{$search-prefix}-threshold-label-initial {
  display: block;
  margin-bottom: 8px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
