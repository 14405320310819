@import '../../../styles/variables';

$andes-pagination: 'andes-pagination';

.#{$search-prefix}-pagination {
  display: flex;
  justify-content: center;
  color: $search-gray-450;
  margin-right: $andes-spacing-8;

  &:last-child {
    margin-bottom: $andes-spacing-32;
  }

  .#{$andes-pagination}__button--current {
    .#{$andes-pagination}__link {
      color: $search-gray-450;
      background-color: $search-gray-004;
      font-weight: 600;
    }
  }

  .#{$andes-pagination}__page-count {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: $andes-spacing-4;
    white-space: nowrap;
  }

  .#{$andes-pagination}__button {
    margin-left: 15px;
  }

  .#{$andes-pagination}__arrow-title {
    color: $search-dodger-blue;
  }

  .#{$andes-pagination}__arrow {
    width: 8px;
    height: 8px;
    margin-left: 2px;
    border-color: $search-dodger-blue;
  }
}
