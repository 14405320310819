@import '../../../styles/dropdown.desktop';
@import '../../../../../styles/variables';

.#{$search-prefix}-sort-filter {
  &__title {
    color: $search-gray-666;
    font-size: $font-size-16;
    font-weight: $font-weight-semibold;
    line-height: 6px;
    margin: 0 0 12px;
  }

  .andes-dropdown.andes-dropdown--open .andes-dropdown__trigger {
    box-shadow: none;
  }

  .andes-card__content {
    @media (width <= 770px) {
      min-width: 125px;
    }
    min-width: 135px;
    overflow: hidden;
  }

  .andes-widther {
    height: 16px;
    width: 120px;
  }

  &--with-disclaimer {
    margin-top: 0;

    .andes-dropdown__trigger:first-child::after {
      content: '';
      position: relative;
      right: 29px;
      top: 3px;
      font-size: 19px;
    }

    .andes-dropdown__arrow {
      margin-left: $search-spacing-18;
    }
  }

  .andes-list__item {
    &--with-disclaimer {
      .andes-list__item-text::after {
        content: $disclaimer-asterisk;
        font-size: 19px;
        position: absolute;
        bottom: $andes-spacing-12;
      }
    }
  }
}

.#{$search-prefix}-sort-filter-values {
  color: $search-gray-666;
  cursor: pointer;
  display: block;
  font-size: 14px;
  width: 100px;
  position: relative;
  top: 2px;
  font-weight: $font-weight-regular;
}
