@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.#{$search-prefix}-installments {
  &-prefix {
    color: $andes-black;
    display: inline-flex;
    margin: 0 0.3rem 0 0;
    align-items: baseline;

    .#{$search-prefix}-price__part {
      margin-left: 0;
    }
  }
}
