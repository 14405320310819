// sass-lint:disable no-vendor-prefixes

.#{$search-prefix}-carousel-card {
  .andes-money-amount {
    line-height: 1em;
  }

  .andes-money-amount__discount {
    font-size: $font-size-12 !important;
  }
}

.#{$search-prefix}-carousel-card-item {
  &__container-image {
    padding: 9px;

    .#{$search-prefix}-image {
      object-fit: contain;
      display: block;
    }
  }

  &__link {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-weight: $font-weight-light;
    line-height: 1.14;
    overflow: hidden;
    text-overflow: ellipsis;

    &::before {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      left: 0;
      content: '';
    }
  }
}
