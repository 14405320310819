@import '../components/card/styles/card';
@import '../components/card/components/main/styles/main-card.desktop';
@import '../components/card/components/item/styles/item';
@import '../components/card/components/item/styles/item.desktop';
@import '../components/card/components/view-more/styles/view-more.desktop';

// sass-lint:disable no-vendor-prefixes
// sass-lint:disable nesting-depth
.#{$search-prefix}-carousel-wrapper__desktop {
  display: flex;
  justify-content: space-between;

  .#{$search-prefix}-carousel-andes {
    min-height: 291px;
    min-width: 600px;

    .andes-carousel-snapped__wrapper {
      height: 254px;
    }

    .andes-carousel-snapped {
      padding-bottom: 37px;
      padding-top: $andes-spacing-8;
    }

    .andes-carousel-snapped__container {
      margin-left: 0 !important;
      width: 100% !important;
    }
  }
}
