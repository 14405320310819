@import '../../../../styles/variables';

// sass-lint:disable no-vendor-prefixes

@mixin specialized-title {
  margin-top: $search-spacing-8;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-overflow: ellipsis;
  word-break: break-word;
}

.#{$search-prefix}-filter-intervention {
  margin-bottom: $search-spacing-20;

  .andes-carousel-snapped {
    margin-top: $search-spacing-14;
    width: 100%;
    height: 130px;

    &__slide {
      cursor: auto;
    }

    &__control--previous {
      left: -50px;
      z-index: 900;
    }

    &__control {
      box-shadow: unset;
      background-color: unset;

      &--next {
        right: -50px;
      }

      &:hover {
        box-shadow: unset;
      }

      &:focus {
        box-shadow: unset;
      }
    }
  }

  .andes-thumbnail {
    img {
      height: auto;
      width: 62px;
    }
  }

  .andes-thumbnail--80 {
    height: 86px !important;
    width: 86px !important;
  }

  .andes-carousel-snapped__container--content {
    margin-left: unset !important;
  }

  &-title {
    font-size: $font-size-16;
    line-height: $line-height-20;
    color: $andes-gray-900;
    font-weight: weight(normal);
    display: inline-block;
    width: 70%;

    &__SEMIBOLD {
      font-weight: weight(semibold);
    }
  }

  .ui-search-modal__link {
    text-align: right;
    font-size: $font-size-16;
    line-height: $line-height-20;
    font-weight: weight(semibold);
    vertical-align: top;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .#{$search-prefix}-link {
      color: $search-dodger-blue;
    }

    .#{$search-prefix}-link:hover {
      color: $search-dodger-blue;
    }
  }

  &__view-all-link {
    margin-top: $search-spacing-16;
    line-height: $search-spacing-28;
    font-size: $font-size-14;
    width: max-content;
  }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 86px;
    overflow: hidden;
  }

  &-specialized-item-slide {
    text-align: center;
  }

  &-specialized-title-large {
    @include specialized-title;

    -webkit-line-clamp: 2;
  }

  &-specialized-title-short {
    @include specialized-title;

    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  &-specialized-see-more {
    display: flex;
    border: none;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 0px;
  }

  &-specialized-subtitle {
    font-size: $font-size-12;
    line-height: $line-height-15;
    font-weight: weight(semibold_light);
    color: $search-gray-r080;
    display: block;
  }

  .ui-search-modal__link {
    font-size: $font-size-12;
    font-weight: $font-weight-semibold;
    color: black;
    margin-top: 7px;
    padding: 0px;
    display: flex;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    line-height: inherit;
  }
}
