@import '../../../styles/variables';

.#{$search-prefix}-search-result {
  display: flex;
  align-items: center;
  margin-bottom: $andes-spacing-16;
  margin-top: $search-spacing-4;

  &__quantity-results {
    font-size: $font-size-14;
    line-height: 1.29;
    font-weight: $font-weight-light;
    width: max-content;
    color: $search-gray-1000;
    position: relative;
    bottom: 2px;
  }
}

.#{$search-prefix}-sidebar__result-container {
  .#{$search-prefix}-search-result {
    margin-bottom: 0;
    margin-top: 0;
  }
}
