// sass-lint:disable no-qualifying-elements, no-misspelled-properties
@import '../../../../../styles/variables';
@import '~@andes/switch/index';

.ui-search-filter-dl:first-child .#{$search-prefix}-filter-highlighted {
  margin-top: -3px;
}

.#{$search-prefix}-filter-highlighted-SHIPPING_ORIGIN_HIGHLIGHTED .ui-search-filter-highlighted__content {
  padding-top: 2px;
  padding-bottom: 5px;
  width: 155px;
}

.ui-search-sidebar .#{$search-prefix}-filter-highlighted {
  &__content-button {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}

.#{$search-prefix}-filter-highlighted-coin_highlighted {
  form>button>label {
    display: flex;
    background-color: $search-yellow-fff1cb;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    color: $search-brown-a47b2e;
    height: 20px;
    padding-left: 4px;
    padding-right: 4px;

    svg {
      margin-left: 4px;
    }
  }
}

.andes-tooltip--highlight.andes-card {
  width: 312px;
  padding-right: 32px;
}

.#{$search-prefix}-filter-highlighted {
  $root: &;

  width: 242px;
  margin-top: -24px;
  cursor: pointer;

  &__title {
    margin-top: 1px;
    display: inline-block;
    font-weight: $font-weight-semibold;
    font-size: $font-size-14;
    line-height: $line-height-15;
    white-space: nowrap;
    color: $search-gray-r080;
  }

  &__label {
    margin-top: 4px;
    display: inline-block;
    font-weight: normal;
    font-size: $font-size-12;
    color: $search-gray-450;
  }

  &__subtitle {
    margin-top: 5px;
    font-size: $font-size-12;
    font-weight: normal;
    display: inline-block;
    color: $search-gray-r080;
  }

  .ui-search-item__highlight-label {
    font-size: $font-size-14;
  }

  .ui-search-icon {
    &--full {
      width: 45px;
      height: 15px;
      vertical-align: middle;
      margin-right: 2px;
    }

    &--international-filter-cbt {
      margin-bottom: -5px;
    }
  }

  &__switch-icon {
    bottom: $search-spacing-9;
    padding: 0;
  }

  &__switch-container {
    display: flex;
    align-items: center;

    //Cuando se habilite el switch grande -borrar este bloque-
    .andes-switch__input {
      display: flex;
      align-items: center;

      &::before {
        height: 18px;
        width: 34px;
      }

      &::after {
        top: 4px;
        width: 16px;
        height: 16px;
      }

      &:checked::after {
        left: 16px;
      }
    }
  }

  &__content {
    line-height: 15px;

    .#{$search-prefix}-item__highlight-label {
      color: $search-gray-r080;
    }
  }

  &__content-button {
    background: none;
    border: none;
    text-align: left;
    padding: 0;
    cursor: pointer;
    min-height: 21.594px;
    width: -webkit-fill-available;
    flex-grow: 5;
    z-index: $z-index-xl;

    label {
      cursor: pointer;
    }
  }

  &__tooltip {
    z-index: 11;
  }

  &__container {
    background: $search-white-100;
    border: 1px solid $search-gray-500;
    border-radius: 6px;
    padding: 15px 12px 15px 16px;
    margin: 4px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
    gap: 10px;

    .andes-tooltip__trigger,
    &>svg.ui-search-icon {

      &--visible,
      &>div[class=''] {
        display: flex;
      }
    }

    .andes-tooltip__content {
      margin-right: 0;

      .andes-button {
        padding: $search-spacing-9 $search-spacing-12 $search-spacing-7;
        gap: $search-spacing-10;
        width: 107px;
        height: 32px;
        border-radius: 5px;

        .andes-button__content {
          font-size: 14px;
        }
      }
    }

    @media (width <=1024px) {
      padding: 11px;
      width: 95%;
    }
  }

  &-WITH_VIRTUAL_TOUR_HIGHLIGHTED {
    #{$root}__title {
      display: flex;
      align-items: center;

      >svg {
        margin-right: $andes-spacing-8;
      }
    }
  }
}
