@import '../../../../styles/variables';

// sass-lint:disable nesting-depth

.#{$search-prefix}-variations-picker {
  display: flex;
  align-items: center;

  .andes-widther {
    display: inline-block;
    max-width: 80%;
  }

  .andes-dropdown__trigger {
    color: $search-gray-1000;

    .andes-dropdown__arrow {
      margin-left: $andes-spacing-4;
      top: 1px;
    }
  }

  .andes-card__content {
    max-width: unset;
    min-width: unset;
    box-shadow: 0 3px 4px 0 $search-gray-r010, 0 0 1px 0 $search-gray-r025;

    .andes-list {
      display: flex;
      padding: $andes-spacing-8;

      li::after {
        background: unset !important;
      }

      .andes-list__item {
        opacity: 0.4;
        transition: opacity 0.3s ease-out;
        padding: 0 $andes-spacing-4;
        height: auto;

        &:hover {
          opacity: 1;
        }

        &--selected {
          opacity: 1;
          background-color: unset;
        }

        &--selected::before {
          display: none;
        }

        &-first-column {
          padding: 0;
          margin: 0;
        }

        .andes-list__item-asset {
          padding: 0;
          margin-right: 0;
        }

        .andes-list__item-asset .andes-list__item-image {
          margin-right: 0;
          border-radius: $border-radius-3;
          width: 42px;
          height: 42px;
          object-fit: contain;
        }

        .andes-list__item-text {
          display: none;
        }
      }

      &--selectable {
        .andes-list__item:hover {
          background-color: unset;
        }
      }
    }
  }

  &--stack {
    margin-top: 19px;

    .ui-search-variations-picker__view-more {
      @include square(41px);

      line-height: 39px;
    }
  }

  &--grid {
    display: none;

    .ui-search-variations-picker__view-more {
      @include square(35px);

      line-height: 34px;
    }
  }

  &__label {
    display: inline-block;
    color: $search-gray-666;
    font-weight: $font-weight-light;
    font-size: $font-size-14;
    margin-right: $andes-spacing-4;
  }

  &__variations-list {
    display: flex;
    margin: $andes-spacing-8 $andes-spacing-8 $andes-spacing-8 $andes-spacing-20;
  }

  &__variation {
    @include square(36px);

    flex: 1 1;
    flex-basis: auto;
    max-width: 36px;
    position: relative;
    opacity: 0.4;
    transition: opacity 0.3s ease-out;
    margin: 0 4px;
    cursor: pointer;

    &-image {
      width: 100%;
      border: 1px solid $search-gray-1200;
      border-radius: $border-radius-3;
      object-fit: contain;
    }

    &--selected {
      opacity: 1;
      cursor: unset;

      .#{$search-prefix}-variations-picker__variation-image {
        border-bottom: 2px solid $andes-blue-500;
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  .ui-search-variations-picker__view-more {
    background: $search-white-900;
    color: $andes-blue-500;
    font-size: $font-size-18;
    font-weight: $font-weight-semi-bold-light;
    position: absolute;
    text-align: center;
    z-index: $z-index-s;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
