.ui-search-filter-highlighted {

    &-modal {
      min-width: 855px;
      min-height: 577px;

      .andes-modal__content {
        height: 480px;
        iframe {
          border: none;
        }
      }
    }
  cursor: default;
    &__container-vertical {
      .ui-search-filter-highlighted {
        &__label-subtitle-fade {
          font-size: $font-size-12;
          font-weight: $font-weight-regular;
          color: $andes-gray-550;
          margin-top: $andes-spacing-4;
          display: inline-block;
        }

        &__label-title-fade {
          font-size: $font-size-14;
          font-style: normal;
          font-weight: $font-weight-semibold;
          line-height: 18px;
          white-space: normal;
          margin-top: $andes-spacing-4;
          display: inline-block;
        }
      }

    }

    &__icons-container {
      display: flex;
      align-items: center;
    }

    &__icon-label {
      background-color: #00A650;
      padding: 3px $andes-spacing-4 ;
      font-size: $font-size-10;
      font-style: normal;
      font-weight: $font-weight-semibold;
      display: flex;
      align-items: center;
      color: $andes-white;
      margin-right: $andes-spacing-8;
      border-radius: 2px;

      .ui-search-icon {
        width: 18px;
        height: 11px;
        margin-right: $andes-spacing-4;
      }
    }

    &__tooltip {
      display: flex;
      justify-content: center;
      border: none;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
    }
  }
