@import '~@andes/normalize/index';
@import '../components/card/shipping/styles/shipping';
@import './variables';
@import './mixins';
@import './functions';

// sass-lint:disable no-color-keywords

.#{$search-prefix}-color--BLACK {
  color: color(solid_black);
}

.#{$search-prefix}-color--LIGHT_GREEN {
  color: color(light_green);
}

.#{$search-prefix}-color--GRAY {
  color: color(solid_gray);
}

.#{$search-prefix}-size--XXX_SMALL {
  font-size: size(xxx_small);
}

.#{$search-prefix}-size--XX_SMALL,
.#{$search-prefix}-size--XXSMALL {
  font-size: size(xx_small);
}

.#{$search-prefix}-size--X_SMALL,
.#{$search-prefix}-size--XSMALL {
  font-size: size(x_small);
}

.#{$search-prefix}-size--SMALL {
  font-size: size(small);
}

.#{$search-prefix}-size--MEDIUM {
  font-size: size(medium);
}

.#{$search-prefix}-size--LARGE {
  font-size: size(large);
}

.#{$search-prefix}-size--X_LARGE {
  font-size: size(x_large);
}

.#{$search-prefix}-weight--LIGHT {
  font-weight: weight(light);
}

.#{$search-prefix}-weight--REGULAR {
  font-weight: weight(regular);
}

.#{$search-prefix}-weight--SEMIBOLD {
  font-weight: weight(semibold);
}

.#{$search-prefix}-weight--BOLD {
  font-weight: weight(bold);
}

.#{$search-prefix}-item__group__element {
  &-separator {
    display: block;
    width: 100%;
    height: 0.1px;
    margin: 0;
    border: none;
  }

  &--shipping {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.screen-reader-only {
  @include sr-only;
}
