@import './variables';
@import '~@andes/common/lib/styles/mixins/sizing';

@mixin gallery-cards-sides-margins-2-cols-small-screen {
  @media (width <= 1023px) and (width >= 862px) {
    &:nth-child(3n + 1) {
      margin-left: 16px;
    }

    &:nth-child(2n + 1) {
      margin-left: 0;
    }
  }
}

@mixin gallery-cards-sides-margins-2-cols-xsmall-screen {
  @media (width <= 861px) and (width >= 720px) {
    &:nth-child(3n + 1) {
      margin-left: $andes-spacing-12;
    }

    &:nth-child(2n + 1) {
      margin-left: 0;
    }
  }
}

@mixin gallery-cards-sides-margins-1-col-xxsmall-screen {
  @media (width <= 719px) {
    margin-left: 0;
  }
}

@mixin gallery-cards-width-medium-xsmall-screen {
  @media (width <= 1164px) and (width >= 1024px), (width <= 861px) and (width >= 720px) {
    min-width: $gallery-small-cards-image-width;
    max-width: $gallery-small-cards-image-width;
    flex-basis: $gallery-small-cards-image-width;
    margin-left: $andes-spacing-12;
    margin-bottom: $andes-spacing-12;
  }
}

@mixin gallery-cards-image-height-medium-xsmall-screen {
  @media (width <= 1164px) and (width >= 1024px), (width <= 861px) and (width >= 720px) {
    min-height: $gallery-small-cards-image-height;
    max-height: $gallery-small-cards-image-height;
  }
}

@mixin gallery-mot-cards-image-height-medium-xsmall-screen {
  @media (width <= 1164px) and (width >= 1024px), (width <= 861px) and (width >= 720px) {
    min-height: var(--min-image-height, #{$gallery-mot-small-cards-image-height});
    max-height: var(--min-image-height, #{$gallery-mot-small-cards-image-height});
  }
}

@mixin gallery-srv-cards-image-height-medium-xsmall-screen {
  @media (width <= 1164px) and (width >= 1024px), (width <= 861px) and (width >= 720px) {
    min-height: var(--min-image-height, #{$gallery-srv-small-cards-image-height});
    max-height: var(--min-image-height, #{$gallery-srv-small-cards-image-height});
  }
}

@mixin official-stores-carousel-breakpoint-m {
  &.#{$search-prefix}-carousel--m {
    width: 685px;
    margin-left: $search-spacing-2;
  }

  @media (width <= 1164px) and (width >= 1024px) {
    width: 685px;
    margin-left: $search-spacing-2;
  }
}

@mixin official-stores-carousel-breakpoint-s {
  &.#{$search-prefix}-carousel--s {
    width: 475px;
    margin-left: 13px;
  }

  @media (width <= 1023px) {
    width: 475px;
    margin-left: 13px;
  }
}

@mixin official-stores-carousel-arrows-breakpoint-m {
  @media (width <= 1164px) and (width >= 1024px) {
    .carousel-container {
      .next-button {
        right: -26px;
      }

      .prev-button {
        left: -35px;
      }
    }
  }
}

@mixin official-stores-carousel-arrows-breakpoint-s {
  @media (width <= 1023px) {
    .carousel-container {
      .next-button {
        right: -21px;
      }

      .prev-button {
        left: -35px;
      }
    }
  }
}

@mixin brands-carousel-breakpoint-l {
  &.#{$search-prefix}-carousel--l {
    width: 759px;
    margin-left: $andes-spacing-20;
  }

  @media (width <= 1164px) and (width >= 1024px) {
    width: 759px;
    margin-left: $andes-spacing-20;
  }
}

@mixin brands-carousel-breakpoint-m {
  &.#{$search-prefix}-carousel--m {
    width: 599px;
    margin-left: $andes-spacing-16;
  }

  @media (width <= 1023px) and (width >= 862px) {
    width: 599px;
    margin-left: $andes-spacing-16;
  }
}

@mixin brands-carousel-breakpoint-s {
  &.#{$search-prefix}-carousel--s {
    width: 507px;
    margin-left: 23px;
  }

  @media (width <= 861px) {
    width: 507px;
    margin-left: 23px;
  }
}

@mixin brands-carousel-arrows-breakpoint-l {
  &.#{$search-prefix}-carousel--l {
    .carousel-container {
      .next-button {
        right: -9px;
      }
    }
  }

  @media (width <= 1164px) and (width >= 1024px) {
    .carousel-container {
      .next-button {
        right: -9px;
      }
    }
  }
}

@mixin brands-carousel-arrows-breakpoint-m {
  &.#{$search-prefix}-carousel--m {
    .carousel-container {
      .next-button {
        right: -11px;
      }
    }
  }

  @media (width <= 1023px) and (width >= 862px) {
    .carousel-container {
      .next-button {
        right: -11px;
      }
    }
  }
}

@mixin brands-carousel-arrows-breakpoint-s {
  &.#{$search-prefix}-carousel--s {
    .carousel-container {
      .next-button {
        right: -6px;
      }
    }
  }

  @media (width <= 861px) {
    .carousel-container {
      .next-button {
        right: -6px;
      }
    }
  }
}

@mixin cpg-carousel-breakpoint-l {
  @media (width <= 1164px) and (width >= 1024px) {
    width: 756px;
  }
}

@mixin cpg-carousel-breakpoint-m {
  @media (width <= 1023px) and (width >= 862px) {
    width: 595px;
  }
}

@mixin cpg-carousel-breakpoint-s {
  @media (width <= 861px) {
    width: 500px;
  }
}

@mixin cpg-carousel-slides-breakpoint-l {
  @media (width <= 1164px) and (width >= 1024px) {
    max-width: 175px;
    margin-left: 6px;
    height: 261px;
  }
}

@mixin cpg-carousel-slides-breakpoint-m {
  @media (width <= 1023px) and (width >= 862px) {
    max-width: 184px;
    margin-left: 6px;
    height: 269px;
  }
}

@mixin cpg-carousel-slides-breakpoint-s {
  @media (width <= 861px) {
    max-width: 155px;
    margin-left: $andes-spacing-4;
  }
}

@mixin cpg-carousel-main-card-breakpoint-l {
  @media (width <= 1164px) and (width >= 1024px) {
    width: 364px;
  }
}

@mixin cpg-carousel-main-card-breakpoint-m {
  @media (width <= 1023px) and (width >= 862px) {
    width: 385px;
  }
}

@mixin cpg-carousel-main-card-breakpoint-s {
  @media (width <= 861px) {
    width: 322px;
  }
}

@mixin cpg-carousel-generic-card-breakpoint-l {
  @media (width <= 1164px) and (width >= 1024px) {
    max-width: 175px;
  }
}

@mixin cpg-carousel-generic-card-breakpoint-m {
  @media (width <= 1023px) and (width >= 862px) {
    max-width: 184px;
  }
}

@mixin cpg-carousel-generic-card-breakpoint-s {
  @media (width <= 861px) {
    max-width: 155px;
  }
}

@mixin cpg-carousel-generic-card-image-breakpoint-l {
  @media (width <= 1164px) and (width >= 1024px) {
    @include square(157px);
  }
}

@mixin cpg-carousel-generic-card-image-breakpoint-m {
  @media (width <= 1023px) and (width >= 862px) {
    @include square(167px);
  }
}

@mixin cpg-carousel-generic-card-image-breakpoint-s {
  @media (width <= 861px) {
    @include square(137px);
  }
}

@mixin cpg-carousel-view-more-card-breakpoint-l {
  @media (width <= 1164px) and (width >= 1024px) {
    width: 175px;
  }
}

@mixin cpg-carousel-view-more-card-breakpoint-m {
  @media (width <= 1023px) and (width >= 862px) {
    width: 184px;
  }
}

@mixin cpg-carousel-view-more-card-breakpoint-s {
  @media (width <= 861px) {
    width: 155px;
  }
}
