@import './icons';

.#{$search-prefix}-icon {
  &--full {
    @include size(52px, auto);
  }

  &--chevron {
    stroke: currentcolor;
  }

  &--loyalty {
    @include size(16px, auto);

    fill: $search-green-150;
    max-height: 16px;
  }

  &--rest {
    @include size(36px, 34px);

    fill: $search-dodger-blue;
  }

  &--sum {
    @include size(36px, 34px);

    fill: $search-dodger-blue;
  }

  &--close {
    @include size(12px, 12px);
  }

  &--supermarket-logo {
    width: 115px;
    height: 18px;
  }

  &--meliplus {
    position: relative;
    top: 3px;
  }
}
