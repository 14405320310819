@import '../../../styles/variables';

.#{$search-prefix}-money-picker {
  display: inline-flex;
  margin-left: $andes-spacing-12;
  margin-bottom: 10px;
  line-height: 1.25;

  &-dt-title {
    font-weight: $font-weight-semibold;
    font-size: $font-size-16;
    margin-bottom: 10px;
    line-height: 1.25;
    color: $search-gray-1000;
    display: inline-flex;

    &::after {
      color: $search-gray-700;
      height: 14px;
      margin-left: 15px;
      width: 1px;
      content: '|';
    }
  }

  &__form {
    display: inline-flex;
    width: 105px;
  }

  &__btn {
    background-color: transparent;
    display: inline-flex;
    border: none;
    font-size: $font-size-16;
    font-weight: $font-weight-semibold;
    align-content: center;
    justify-content: center;
    color: $andes-gray-250;
    cursor: pointer;
  }

  &__btn:focus {
    box-shadow: none;
  }

  &__btn:disabled {
    color: $search-gray-1000;
  }

  &__li {
    margin-bottom: 6px;

    .#{$search-prefix}-link {
      color: $search-gray-666;
      font-weight: $font-weight-regular;
      font-size: $font-size-14;
      margin-left: 0;
      display: flex;
      flex-wrap: wrap;

      &:hover {
        color: $search-gray-1000;
      }
    }
  }
}
