@import '../../../../styles/variables';
@import './highlight-label';

.#{$search-prefix}-item__highlight-label {
  font-size: $font-size-12;

  &--media {
    padding-top: 0;
  }

  .#{$search-prefix}-icon--black-friday {
    position: relative;
  }

  .#{$search-prefix}-icon--hot-sale {
    position: relative;
    top: 2px;
    right: 4px;
    margin-left: 4px;
  }

  &--composed-title {
    font-size: $font-size-12;
  }

  .#{$search-prefix}-icon--cart-volume-discount {
    margin-right: 4px;
  }
}

.#{$search-prefix}-result {
  .#{$search-prefix}-item__highlight-label {
    &--media {
      margin-top: 0;
    }
  }
}
