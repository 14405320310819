@import '../../../styles/variables';

// sass-lint:disable no-ids
// sass-lint:disable id-name-format
// sass-lint:disable class-name-format

.#{$search-prefix}-sidebar-advertising {
  max-width: 100%;
  position: relative;

  a:hover {
    text-decoration: none;
  }

  &__title-container {
    padding-bottom: $andes-spacing-40;
    border-top: solid 1px $search-gray-750;
  }

  &__title {
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    color: $search-gray-700;
    background: $search-gray-10;
    padding-right: 10px;
    position: absolute;
    top: -6px;
  }

  &.title-advertising {
    margin-top: 60px;
  }

  xw .url,
  .description {
    font-weight: $font-weight-light;
  }

  .url {
    margin-top: 2px;
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__display {
    padding: 0;
    max-height: 486px;
    margin-bottom: $andes-spacing-40;
  }

  &__performance {
    padding: 0 0 $andes-spacing-40;
  }

  .imageAd {
    margin: $andes-spacing-40 0;
  }
  .imageAd .image-pAds {
    margin-bottom: 10px;
    width: 80px;
    height: 80px;
  }

  .imageAd .pAdsTitle {
    color: $search-gray-1000;
  }

  .adHere {
    font-size: $font-size-14;
    border-top: 1px solid $search-gray-750;
    width: 100%;
    color: $andes-blue-500;
    height: 30px;
    padding-top: 13px;

    a {
      color: $andes-blue-500;

      &:hover {
        color: $search-blue-600;
      }
    }
  }
}
