.#{$search-prefix}-carousel-card--MAIN {
  height: 100%;
  width: 344px;
  z-index: 1;
  display: inline-block;
  max-width: max-content !important;

  .#{$search-prefix}-carousel-card__content {
    display: flex;
    height: 100%;
    justify-content: space-between;
    position: relative;
  }
}

.#{$search-prefix}-carousel-card-main {
  &__data {
    margin: $andes-spacing-32 $andes-spacing-8 $andes-spacing-20 $andes-spacing-24;
    width: 180px;
  }

  &__title {
    color: $andes-gray-900;
    font-size: $font-size-24;
    font-weight: $font-weight-semibold;
    letter-spacing: -0.2px;
    line-height: 1.16;
  }

  &--clean-title {
    font-size: 23px;
  }

  &__logo--supermarket {
    width: 150px;
    height: $andes-spacing-24;
  }

  &__subtitle {
    color: $andes-gray-900;
    font-size: $font-size-16;
    font-weight: $font-weight-regular;
    line-height: 1.31;
    margin-top: 17px;
    width: 180px;
  }

  &__link-image {
    img {
      height: 132px !important;
      margin: 36px $andes-spacing-24 0 0;
      width: 108px;
    }

    &::before {
      content: '';
      height: 100%;
      width: 100%;
      display: block;
      position: absolute;
      left: 0;
    }
  }

  &__link {
    border-top: 1px solid $andes-gray-100;
    bottom: 15px;
    color: $andes-blue-500;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    letter-spacing: -0.1px;
    line-height: 1.71;
    padding-left: $andes-spacing-20;
    padding-top: $andes-spacing-20;
    position: absolute;
    right: 0;
    width: 100%;

    &::after {
      border: solid $andes-blue-500;
      border-width: 0 1.2px 1.2px 0;
      content: '';
      display: inline-block;
      margin: 0 0 2px $andes-spacing-4;
      padding: 2px;
      transform: rotate(-45deg);
    }
  }
}
