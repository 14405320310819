// sass-lint:disable no-vendor-prefixes
// sass-lint:disable nesting-depth

.#{$search-prefix}-carousel-wrapper {
  &__desktop {
    .#{$search-prefix}-carousel-card-view-all {
      &__container {
        height: 100%;
        width: 100%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        max-width: max-content !important;

        .andes-button {
          font-size: $font-size-14;
          height: 36px;
          margin: 34px $andes-spacing-16 $andes-spacing-16;
          padding: 0;
          width: 132px;
          line-height: 1em;
        }

        .andes-button--loud::before {
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          bottom: 0;
          right: 0;
          transform: unset;
          background-color: unset;
        }
      }

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $search-gray-500;
        border-radius: 50%;
        height: 62px;
        margin-top: 68px;
        width: 62px;
      }

      &__title {
        font-size: $font-size-14;
        line-height: 1;
        font-weight: $font-weight-regular;
        text-align: center;
        color: $andes-gray-900;
        margin-top: $andes-spacing-24;
      }
    }
  }
}
