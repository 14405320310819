@import '../../../styles/variables';
@import '../../../styles/common';
@import '~@andes/pagination/index';
@import '~@andes/card/index';
@import '~@andes/modal/index';
@import '~@andes/tag/index';
@import '~@andes/button/index';
@import '~@andes/button/lib/styles/modifier/transparent';
@import '~@andes/snackbar/index';
@import '~@andes/snackbar/lib/styles/desktop';
@import '~@andes/snackbar/lib/styles/success';
@import '~@andes/snackbar/lib/styles/error';
@import '~@andes/breadcrumb/index';
@import '~@andes/form/index';
@import '~@andes/textfield/index';
@import '~@andes/form/lib/styles/message';
@import '~@andes/tooltip/index';
@import '~@andes/dropdown/base';
@import '~@andes/badge/index';
@import '~@andes/carousel-snapped/index';
@import '~@andes/coach-marks/index';
@import '~@andes/money-amount/index';
@import '~@andes/technical-error/index';
@import '~@andes/thumbnail/base';
@import '~@andes/thumbnail/lib/styles/modifier/circle';
@import '~@andes/typography/index';

@import '~@andes/dropdown/lib/styles/standalone/size/small';
@import '~@andes/dropdown/index';
@import '~@andes/list/index';
@import '~@andes/modal/base';
@import '~@andes/message/index';
@import '~@andes/message/lib/styles/neutral';
@import '~cpg-nordic/src/styles/cpg.desktop';
@import '~@andes/progress-indicator-circular/index';
@import '~@andes/input-stepper/base';
@import '~@andes/input-stepper/lib/styles/size/small';
@import '~@andes/common/lib/styles/visually-hidden';
@import '~@andes/progress-indicator-linear/base';
@import '~ui-library_ml/src/components/carousel/styles/paged';
@import '~ui-library_ml/src/components/carousel/styles/arrows';
@import '~@recommendations-fe/shops/src/styles/recommendations-fe-shops.desktop';
@import '@ui-components-web/ml-compats/src/styles/desktop';
@import '../../../components/icons/icons.desktop';
@import '../../../components/card/spotlight/styles/spotlight.desktop';
@import '../../../components/sidebar/styles/sidebar.desktop';
@import '../../../components/sidebar/components/view-options/styles/view-options.desktop';
@import '../../../components/sidebar/components/sort-filter/styles/sort-filter.desktop';
@import '../../../components/sidebar/components/filter-official-store/styles/filter-official-store.desktop';
@import '../../../components/sidebar/components/highlighted-filter/styles/highlighted-filter.desktop';
@import '../../../components/sidebar/components/highlighted-filter-vis/styles';
@import '../../../components/animated-switch/styles/animated-switch';
@import '../../../components/view-change/styles/view-change.desktop';
@import '../../../components/sidebar/components/breadcrumb/styles/breadcrumb.desktop';
@import '../../../components/sidebar/components/filters-colors/styles/filtersColors.desktop';
@import '../../../components/sidebar/components/filters-tooltip/styles/filters-tooltip.desktop';
@import '../../../components/sidebar/components/filters/styles/filters.desktop';
@import '../../../components/filters/range-filters/styles/range-filters.desktop';
@import '../../../components/sidebar/styles/search-result.desktop';
@import '../../../components/layout/styles/layout.desktop';
@import '../../../components/filters/applied/styles/applied-filters.desktop';
@import '../../../components/modal/styles/modal.desktop';
@import '../../../components/money-picker/styles/money-picker.desktop';
@import '../../../components/pagination/styles/pagination.desktop';
@import '../../../components/sidebar/components/search-modal/styles/search-modal.desktop';
@import '../../../components/bookmark/styles/bookmark';
@import 'app/components/top-keywords/styles/top-keywords.desktop';
@import '../../../components/reviews/styles/reviews.desktop';
@import '../../../components/sidebar/styles/dropdown.desktop';
@import '../../../components/card/variations/styles/variations-picker';
@import '../../../components/card/variations/styles/variations-pill';
@import '../../../components/card/variations/styles/variations.desktop';
@import '../../../components/main-header/styles/main-header.desktop';
@import '../../../components/card/pds/styles/pds-options';
@import '../../../components/card/promise/styles/promise';
@import '../../../components/card/attributes/styles/attributes.desktop';
@import '../../../components/card/pds/styles/pds-card.desktop';
@import '../../../components/card/highlight/styles/highlight-label.desktop';
@import '../../../components/alert/styles/alert.desktop';
@import '../../../components/carousel/styles/carousel.desktop';
@import '../../../components/intervention/billboard/styles/billboard-intervention.desktop';
@import '../../../components/intervention/vehicle-body-selector/styles/vehicle-body-selector-intervention.desktop';
@import '../../../components/header/styles/deal-header.desktop';
@import '../../../components/header/styles/exhibitor-header.desktop';
@import '../../../components/header/styles/official-store-header.desktop';
@import '../../../components/tooltip/styles/tooltip.desktop';
@import '../../../components/banner/styles/banner.desktop';
@import '../../../components/reputation-scale/styles/reputation-scale';
@import '../../../components/cross-site-modal/styles/cross-site-modal.desktop';
@import '../../../components/advertising/styles/advertising';
@import '../../../components/listing-disclaimer/styles/listing-disclaimer.desktop';
@import '../../../components/footer-disclaimer/styles/footer-disclaimer.desktop';
@import '../../../components/vehicle-body-selector/styles/vehicle-body-selector';
@import '../../../components/bottom-ads/styles/bottom-ads';
@import '../../../components/card/add-to-cart/styles/add-to-cart.desktop';
@import '../../../components/suggestions/styles/suggestions.desktop';
@import '../../../components/zrp-disclaimer/styles/zrp-disclaimer.desktop';
@import '../../../components/info-text-box/styles/info-text-box.desktop';
@import '../../../components/collapsible-card/styles/collapsible-card.desktop';
@import '../../../components/collapsible-card-list/collapsible-card-list';
@import '../../../components/seo-contents/styles/seo-contents.desktop';
@import '../../../components/footer-components/styles/footer-components.desktop';
@import '../../../components/full-snackbar/styles/full-snackbar.desktop';
@import '../../../components/full-snackbar/styles/full-popup.desktop';
@import '../../../components/card/rebates/styles/rebates';
@import '../../../components/card/melicoin/styles/index';
@import '../../../components/card/promotions/styles/index';
@import '../../../components/pricing-banner/styles/pricing-banner.desktop';
@import '../../../components/card/installments/styles/installments.desktop';
@import '../../../components/card/value-proposition/styles/value-proposition.desktop';
@import '../../../components/card/vertical-highlight/styles/vertical-highlight.desktop';
@import '../../../components/value-propositions-details/styles/value-propositions-details-modal.desktop';
@import '../../../components/boleto-discount-message/styles/boleto-discount-message.desktop';
@import '../../../components/card/price-details/styles/price-details.desktop';
@import '../../../components/card/price/styles/price';
@import '../../../components/svg-sprites/styles/svg-sprites';
@import '../../../components/carousel-search/styles/carousel-search.desktop';
@import '../../../components/compats/styles/index';
@import '../../../components/intervention/filter-intervention/styles/filter-intervention.desktop';
@import '../../../components/card/promise-message/styles/promise-message';
@import '../../../components/brand-ads/styles/brand-ads.desktop';
@import '../../../components/intervention/shopping-cart/styles/shopping-cart-intervention';
@import '../../../components/free-shipping-threshold-label/styles/free-shipping-threshold-label';
@import '../../../components/intervention/meliplay/styles/desktop';
@import '../../../components/carousel-andes-search/styles/carousel-andes-search.desktop';
@import '../../../components/pills-builder/styles/pill.desktop';
@import '../../../components/winner-alternatives/styles/winner-alternatives.desktop';
@import '../../../components/winner-alternatives/styles/composed-label-alternative.desktop';
@import '../../../components/eshops/styles/eshops.desktop';

@import '~@seo-frontend-components/card-blog-carousel/index';
@import '~@seo-frontend-components/card-blog-entry/index';
@import '../../../components/seo-contents/styles/seo-contents.desktop';
@import '../../../components/footer-components/styles/footer-components.desktop';

// sass-lint:disable no-vendor-prefixes, no-qualifying-elements
main[role='main'] {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  flex-grow: 1;
}

.#{$search-prefix} {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: visible;

  &-main {
    display: flex;
    margin-top: 32px;
    width: 100%;

    &--official-store {
      margin-top: 20px;
    }

    &--with-topkeywords {
      margin-top: 0;
    }
  }

  // sass-lint:disable bem-depth, class-name-format
  .ui-cpg__department__menu {
    z-index: $z-index-l;
  }
}

.#{$search-prefix}-top-keywords ~ .#{$search-prefix}-main {
  margin-top: 0;
}

.#{$search-prefix}-top-keywords,
.#{$search-prefix}-main {
  max-width: 1215px;
  padding-left: 10px;
  padding-right: $andes-spacing-28;
  width: 100%;
}

.#{$search-prefix}-result .#{$search-prefix}-item__highlight-label.#{$search-prefix}-item__shipping__highlight {
  font-size: $font-size-14;
  line-height: 16px;
  padding: 1px 4px 2px;
  margin: 0;
  margin-top: $andes-spacing-8;
  display: block;
}

.onboarding-cp-button {
  height: auto;
}

// TODO: piso color y tamaño del chevron del dropdown de andes hasta que salgan desde andes con el fix(14-10)
.andes-dropdown__trigger .andes-dropdown__standalone-arrow svg {
  margin: 2px 3px 0 0;
  width: 15px;
  height: 15px;
  fill: $search-dodger-blue;

  path {
    fill-opacity: 1;
  }
}

.andes-dropdown.andes-dropdown--standalone .andes-floating-menu .andes-dropdown__standalone-arrow path {
  fill: $search-dodger-blue;
}

.ui-compats__inputs {
  .andes-form-control__field:focus-visible {
    box-shadow: none;
  }
}

.andes-dropdown.andes-dropdown--standalone.andes-dropdown--open .andes-floating-menu .andes-dropdown__trigger {
  box-shadow: none;
}

.nav-area a.nav-cart span.nav-header-visually-hidden {
  display: none;
}
