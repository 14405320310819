.#{$search-prefix}-carousel {
  $self: &;
  margin-bottom: 16px;
  height: min-content;

  &__item-generic-main__title {
    .#{$search-prefix}-item-generic-header__logo--supermarket {
      width: 150px;
      height: 25px;
    }
  }

  &__slide {
    height: 254px;
    min-width: 175px;
    margin-right: 6px;

    @media (width <= 1164px) {
      height: 265px;
    }

    @media (width <= 1164px) and (width >= 1024px) {
      min-width: 184.5px;
    }

    @media (width <= 1023px) and (width >= 862px) {
      min-width: 194.3px;
    }

    @media (width <= 861px) {
      min-width: 247px;
    }

    &--official_stores {
      height: 70px;

      &,
      a,
      #{$self}__item--container {
        min-width: 220.5px;

        @media (width <= 1164px) and (width >= 1024px) {
          min-width: 224.3px;
        }

        @media (width <= 1023px) {
          min-width: 234.5px;
        }
      }
    }

    #{$self}--brands & {
      height: 100%;
      min-width: auto;
    }
  }

  &__slide--main {
    margin-left: 6px;
    z-index: 2;

    a {
      @media (width <= 1164px) and (width >= 1024px) {
        min-width: 366px;
      }

      @media (width <= 1023px) and (width >= 862px) {
        min-width: 387px;
      }

      @media (width <= 861px) {
        min-width: 407px;
      }
    }
  }

  &__card {
    overflow: hidden;
  }

  &__item__image-container--view_more {
    #{$self}--brands & {
      left: auto;
      top: auto;
      transform: none;
    }
  }
}
