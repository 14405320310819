// sass-lint:disable no-vendor-prefixes, no-color-literals, no-qualifying-elements
.ui-search-shopping-cart-intervention {
  background-color: #fff;
  width: 100%;
  padding: 16px 0;
  margin: 10px 0;
  padding-left: 16px;

  @media (min-width: 1024px) {
    margin: 24px 0;
    padding: 24px;
    display: flex;
  }

  &__header {
    padding-right: 16px;

    .content-progrees {
      width: 100%;
      appearance: none;
      border-radius: 10px;
      height: 4px;
      margin: 12px 0 8px;

      @media (min-width: 1024px) {
        display: block;
        height: 8px;
        margin-top: 20px;
      }
    }

    .subtitle {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;

      @media (min-width: 1024px) {
        font-size: 16px;
      }
    }

    .subtitle--mt {
      margin-top: $andes-spacing-20;
    }

    .subtitle__link {
      text-decoration: none;
      color: $andes-blue-500;
      font-size: 14px;
      position: absolute;
      bottom: 0;
      left: 0;
      font-weight: normal;
    }

    .ui-search-icon--chevron {
      display: inline-block;
      width: 6px;
      margin-left: 6px;
    }

    @media (min-width: 1024px) {
      width: 270px;
      margin-right: 40px;
      position: relative;
      padding: 0;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;

    &-brand-image {
      width: 48px;
      height: 48px;
      display: inline-block;
      margin-right: 8px;
      border-radius: 6px;
      border: 1px solid #ededed;

      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 6px;
      }
    }

    &-title {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      word-wrap: break-word;

      &--w-100 {
        width: 100%;
      }

      &--w-80 {
        width: 80%;
      }

      @media (min-width: 1024px) {
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        padding-top: 5px;
      }
    }

    &-main-text {
      color: $andes-green-500;
      font-weight: bold;
    }

    .ui-search-icon--full {
      display: inline-block;
      height: 17px;
      width: 55px;
      position: relative;
      top: 4px;
      margin-right: 5px;

      @media (min-width: 1024px) {
        height: 22px;
        width: 65px;
        top: 6px;
      }
    }

    &-options {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      gap: 6px;
    }

    &-pill-option {
      font-family: 'Proxima Nova';
      width: 49%;
      height: 13px;
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
      margin-bottom: 6px;

      @media (min-width: 1024px) {
        width: 100%;
        font-size: 14px;
        line-height: 18px;
      }

      &--free {
        color: $andes-green-500;
        font-weight: 500;
      }

      &-icon {
        width: 13px;
        height: 13px;
        display: inline-block;
        margin-right: 5px;

        svg {
          width: 13px;
          height: 13px;
        }
      }

      .ui-search-icon--shippingtruck {
        fill: $andes-green-500;
      }
    }
  }

  .andes-carousel-free {
    padding: 5px;
    margin-top: 10px;

    .andes-carousel-free__slide:not(:last-child) {
      margin-right: 8px !important;
    }
  }

  .card-item {
    width: 150px;
    box-shadow: 0 3px 5px 0 rgb(0 0 0 /10%);
    border-radius: 6px;
    cursor: pointer;

    @media (min-width: 1024px) {
      width: 166px;
      background-color: #fff;
      box-shadow: 0 0 10px -1 $andes-gray-100;
      height: 247px;
    }

    .shops__price-second-line {
      display: flex;
    }

    .shops__price-second-line__label {
      margin-top: -3px;

      // @media (min-width: 1024px) {
      //   margin-top: unset;
      // }
    }

    .ui-search-price__part {
      font-size: $font-size-14 !important;

      @media (min-width: 1024px) {
        font-size: $font-size-16 !important;
      }
    }

    .shops__price-discount {
      font-size: $font-size-12;
      margin-left: 5px;
      color: $andes-green-500;
    }

    &--super {
      @media (min-width: 1024px) {
        height: 295px;
      }
    }

    &-img {
      width: 136px;
      height: 90px;
      margin-top: 8px;

      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      @media (min-width: 1024px) {
        width: 100%;
        height: 146px;
        border-bottom: 1px solid $andes-gray-100;
        margin-top: 0;
      }
    }

    .card-content {
      padding: 8px 0;
      position: relative;

      &__title {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-size: 10px;
        color: $andes-text-color-secondary;
        font-weight: 400;
        line-height: 1.3;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        padding: 0 10px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-decoration: none;
        height: 25px;

        @media (min-width: 1024px) {
          font-size: 12px;
          color: $andes-text-color-primary;
          -webkit-line-clamp: 2;
          height: 32px;
        }
      }

      &__title:hover {
        text-decoration: none;
      }

      &__item-description {
        padding: 8px 10px;
        font-weight: 500;
        @media (min-width: 1024px) {
          padding: 8px 12px 0;
        }
      }

      &__item-currency {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        @media (min-width: 1024px) {
          margin-top: 8px;
          font-size: 20px;
        }
      }

      &__item-discount {
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: $andes-green-500;
        display: inline-block;
        margin-left: 4px;

        @media (min-width: 1024px) {
          font-size: 14px;
          margin-left: 5px;
        }
      }

      &__decimal-price {
        font-size: 8px;
        position: relative;
        color: unset;
      }

      &__symbol {
        margin-right: 3px;
      }

      &__super-description {
        padding: 8px 12px;
        font-size: 20px;
        font-weight: 500;

        @media (min-width: 1024px) {
          padding: 8px 12px 0;
          padding-top: 8px;
          font-size: 20px;
        }
      }

      &__super-description .shops__price-second-line__label {
        margin-top: -8px;
      }

      .ui-search-icon--full {
        width: 32px;
        display: block;
        margin: 0 10px;

        @media (min-width: 1024px) {
          margin: 8px 0 4px 12px;
          width: 35px;
        }
      }

      .action-content {
        width: 100%;
        padding: 0 12px;

        .andes-button {
          width: 100%;
          background-color: $andes-blue-150;
          color: $andes-blue-500;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          height: 32px;
        }

        .andes-button:hover {
          background-color: $andes-blue-150;
          color: $andes-blue-500;
        }
      }
    }

    .ui-search-card-add-to-cart {
      width: 90%;
      margin: 0 auto;
      min-height: 0;
      margin-top: 31px;
    }

    .andes-button--medium {
      padding: 0 10px;
    }
  }

  .content-options {
    margin-right: 16px;
    margin-top: 23px;

    .andes-button {
      width: 100%;
      padding: 15px;
      background-color: $andes-blue-150;
      color: $andes-blue-500;
      margin-top: 0;
      height: 47px;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;

      &:hover {
        background-color: $andes-blue-150;
        color: $andes-blue-500;
      }
    }
  }

  .andes-carousel-snapped {
    height: 260px;
    padding: 6px 0 0 3px;

    &--super {
      height: 305px;
    }

    &__container {
      width: 63%;
    }
  }

  @media (min-width: 1024px) {
    margin: 24px 0;
    padding: 24px;
    display: flex;
  }
}

.grid__intervention {
  @media (min-width: 1024px) {
    margin-bottom: 40px;
    border-radius: 6px;
  }
}
