.#{$search-prefix}-carousel-card {
  max-width: 164px;

  .andes-money-amount__cents {
    margin-top: -2px !important;
  }

  .andes-money-amount--cents-superscript {
    font-size: $font-size-20 !important;
  }

  .andes-money-amount__discount {
    margin-top: -4px;
  }

  .andes-money-amount--previous {
    position: absolute;
    top: 205px;
  }

  .andes-money-amount-combo__main-container {
    margin-top: 17px;
  }
}

.#{$search-prefix}-carousel-card-item {
  &__container-components-card {
    border-top: 1px solid $andes-gray-100;
    padding: 0 0 $andes-spacing-12 $andes-spacing-12;
  }

  &__link {
    color: $search-gray-550;
    font-size: $font-size-12;
    height: 28px;
    margin: $andes-spacing-8 0 0;
    width: 140px;

    &:hover,
    &:focus-within {
      color: $search-gray-550;
    }
  }
}
